import { Dimensions } from "ui";

export function imageDimensionsFromUrl(url: string): Dimensions {
  const regex = /(\d+)x(\d+)\./;
  const match = url.match(regex);

  if (match) {
    const width = Number(match[1]);
    const height = Number(match[2]);

    return { height, width };
  }

  // Safe fallback
  return {
    height: 100,
    width: 100,
  };
}
