import { LandingPage } from "@web/pages/landing/LandingPage";
import { GetStaticProps } from "next";
import { CameraData } from "@web/types/CameraData";
import { getCameraData } from "@web/utils/camera/getCameraData";

export const getStaticProps: GetStaticProps = async () => {
  const csvData = await getCameraData();
  // Filter out rows that are not verified
  return {
    props: {
      csvData: csvData.filter((row) => row.Verified),
    },
  };
};

type Props = {
  csvData: Array<CameraData>;
};

export default function Index({ csvData }: Props) {
  return <LandingPage cameraDataArray={csvData} />;
}
