import { Body1, ColorClass, Header1, PageBody } from "ui";
import { PageWithHeaderAndFooter } from "@web/components/containers/PageWithHeaderAndFooter";
import { CameraData } from "@web/types/CameraData";
import styles from "./css/LandingPage.module.css";
import { CameraGrid } from "@web/components/grids/CameraGrid";

type Props = {
  cameraDataArray: Array<CameraData>;
};

export function LandingPage({ cameraDataArray }: Props): JSX.Element {
  return (
    <PageWithHeaderAndFooter>
      <div>
        <PageBody hasHorizontalPadding>
          <Header1 colorClass={ColorClass.Primary} textAlign="center">
            Analog Atlas
          </Header1>
          <Body1
            className={styles.description}
            colorClass={ColorClass.Primary}
            textAlign="center"
          >
            An evolving garden of film camera research.
          </Body1>
          <div className={styles.grid}>
            <CameraGrid cameraDataArray={cameraDataArray} />
          </div>
        </PageBody>
      </div>
    </PageWithHeaderAndFooter>
  );
}
