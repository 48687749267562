import { CameraData } from "@web/types/CameraData";
import styles from "./css/CameraGrid.module.css";
import { CameraName, ColorClass } from "ui";
import Image from "next/image";
import { imageDimensionsFromUrl } from "@web/utils/camera/imageDimensionsFromUrl";
import Link from "next/link";
import { cameraNameForUrl } from "@web/utils/camera/cameraNameForUrl";

type Props = {
  cameraDataArray: Array<CameraData>;
};

export function CameraGrid({ cameraDataArray }: Props) {
  return (
    <div className={styles.grid}>
      {cameraDataArray.map((cameraData) => {
        const imageDimensions = imageDimensionsFromUrl(
          cameraData["Link to Image"]
        );
        return (
          <Link
            className={styles.gridItem}
            href={`/cameras/${cameraNameForUrl(cameraData["Camera Name"])}`}
            key={cameraData["Link to Image"]}
            style={{ backgroundColor: cameraData["Color"] }}
          >
            <Image
              alt={cameraData["Camera Name"]}
              className={styles.image}
              height={imageDimensions.height}
              src={cameraData["Link to Image"]}
              style={{
                objectFit: "contain",
              }}
              width={imageDimensions.width}
            />
            <CameraName colorClass={ColorClass.Primary}>
              {cameraData["Camera Name"]}
            </CameraName>
          </Link>
        );
      })}
    </div>
  );
}
